import React from "react";
import { HeroSection } from "./HeroSection";
import { AboutSection } from "./AboutSection";
import { EducationSection } from "./EducationSection";
import { InclusionSection } from "./InclusionSection";

export const LegoRobotics = () => {
    return (
        <>
            <HeroSection />
            <AboutSection />
            <EducationSection />
            <InclusionSection />
        </>
    );
};
