import React from "react";
import { AboutSection } from "./AboutSection";
import { HeroSection } from "./HeroSection";
import { InitiativesSection } from "./InitiativesSection";
import { MissionSection } from "./MissionSection";

export const Home = () => {
    return (
        <>
            <HeroSection />
            <AboutSection />
            <MissionSection />
            <InitiativesSection />
        </>
    );
};
