import React from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import { SocialLink } from "./SocialLink";

export const InstagramLink = () => {
    return (
        <SocialLink to="https://www.instagram.com/initializeunl/">
            <InstagramIcon sx={{ mr: 1 }} />
            @initializeunl
        </SocialLink>
    );
};
