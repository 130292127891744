import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const LinkButton = ({ to, color, children }) => {
    return (
        <Link to={to} style={{ textDecoration: "none" }}>
            <Button color={color} variant="contained">
                {children}
            </Button>
        </Link>
    );
};

export const HashLinkButton = ({ to, color, children }) => {
    return (
        <HashLink to={to} style={{ textDecoration: "none" }}>
            <Button color={color} variant="contained">
                {children}
            </Button>
        </HashLink>
    );
};
