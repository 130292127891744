import React from "react";

import logo from "../../assets/INIT_Icon_White.svg";

import { Box, Icon, List, Typography } from "@mui/material";
import { PageSection } from "../../containers";
import { FooterLink, FooterLinkListItem } from "./FooterLink";
import { EmailLink, InstagramLink, FacebookLink, NvolveULink } from "../index";
import { HashLinkButton } from "../LinkButton/LinkButton";

export const Footer = () => {
    return (
        <PageSection backgroundColor="secondary.main">
            <Box
                sx={{
                    display: { md: "grid", xs: "flex" },
                    gridTemplateColumns: {
                        lg: "repeat(4, 1fr)",
                        md: "repeat(3, 1fr)",
                    },
                    columnGap: "1%",
                    flexDirection: "column-reverse",
                    color: "secondary.contrastText",
                    py: 7,
                    px: { xs: 5 },
                }}
            >
                <Box
                    sx={{
                        display: {
                            lg: "block",
                            xs: "none",
                        },
                    }}
                >
                    <Icon
                        sx={{
                            height: 1,
                            width: "fit-content",
                            pt: 2,
                        }}
                    >
                        <img src={logo} height={"70%"} />
                    </Icon>
                </Box>

                <Box sx={{ my: 2 }}>
                    <Typography variant="overline" sx={{ display: "block" }}>
                        Ready to make an impact?
                    </Typography>
                    <HashLinkButton to="contact" color="warning">
                        Get involved
                    </HashLinkButton>
                    <Typography variant="body1" sx={{ mt: 5 }}>
                        Initialize is a student group acting independently of
                        the University of Nebraska-Lincoln.
                    </Typography>
                </Box>

                <Box sx={{ my: 2 }}>
                    <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                        About
                    </Typography>
                    <List disablePadding sx={{ mt: 2 }}>
                        <FooterLinkListItem>
                            <FooterLink to="/about">Our Mission</FooterLink>
                        </FooterLinkListItem>
                        <FooterLinkListItem>
                            <FooterLink to="/about#faq">FAQ</FooterLink>
                        </FooterLinkListItem>
                        <FooterLinkListItem>
                            <FooterLink to="/contact">Contact Us</FooterLink>
                        </FooterLinkListItem>
                    </List>
                </Box>

                <Box sx={{ my: 2 }}>
                    <Typography variant="h3" sx={{ fontWeight: "bold" }}>
                        Socials
                    </Typography>
                    <List disablePadding sx={{ mt: 2 }}>
                        <FooterLinkListItem>
                            <EmailLink />
                        </FooterLinkListItem>
                        <FooterLinkListItem>
                            <InstagramLink />
                        </FooterLinkListItem>
                        {/* <FooterLinkListItem>
                            <FacebookLink />
                        </FooterLinkListItem> */}
                        <FooterLinkListItem>
                            <NvolveULink />
                        </FooterLinkListItem>
                    </List>
                </Box>
            </Box>
        </PageSection>
    );
};
