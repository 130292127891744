import { createTheme } from "@mui/material/styles";

const themeOptions = {
    palette: {
        type: "light",
        primary: {
            main: "#487e8e",
            contrastText: "#e6e7e8",
        },
        white: {
            main: "#E6E7E8",
            contrastText: "#487e8e",
        },
        secondary: {
            main: "#292d3d",
            light: "#636466",
        },
        success: {
            main: "#71bd9f",
        },
        info: {
            main: "#60a396",
        },
        divider: "rgba(111,66,66,0.12)",
        text: {
            primary: "#292D3D",
            secondary: "#2c2c2c",
            disabled: "#636466",
            hint: "#292d3d",
        },
        warning: {
            main: "#f7da63",
        },
        background: {
            default: "#e6e7e8",
            paper: "#fff",
            dark: "#292d3d",
        },
    },
    typography: {
        fontSize: 18,
        fontFamily: ["Gilroy", "Roboto", "Lucida Sans", "sans-serif"].join(","),
        h1: {
            fontSize: "3.5em",
            fontWeight: 700,
            textTransform: "uppercase",
        },
        h2: {
            fontSize: "2rem",
            fontWeight: 700,
            fontFamily: ["Roboto", "Lucida Sans", "sans-serif"].join(","),
        },
        h3: {
            fontSize: "1.5rem",
            fontWeight: 700,
            fontFamily: ["Roboto", "Lucida Sans", "sans-serif"].join(","),
        },
        h4: {
            fontSize: "1rem",
            fontWeight: 700,
            letterSpacing: "0.1em",
        },
        body1: {
            fontSize: "1rem",
        },
        body2: {
            fontSize: "0.9rem",
            fontWeight: 300,
        },
        button: {
            fontSize: "1rem",
            fontFamily: ["Roboto", "Lucida Sans", "sans-serif"].join(","),
        },
    },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: false,
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
            variants: [
                {
                    props: { variant: "contained" },
                    style: {
                        fontWeight: "bold",
                        letterSpacing: ".05em",
                    },
                },
            ],
        },
    },
};

export const theme = createTheme(themeOptions);
