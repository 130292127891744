import { Button, Card, CardContent, CardHeader } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { HashLink } from "react-router-hash-link";

export const InitiativeCard = ({ title, subtitle, children, link = "" }) => {
    return (
        <Card
            sx={{
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                title={title}
                sx={{
                    backgroundColor: "secondary.main",
                    color: "secondary.contrastText",
                    minHeight: { lg: 100, md: 150, xs: 100 },
                }}
                subheader={subtitle}
                subheaderTypographyProps={{
                    color: "secondary.contrastText",
                }}
            />
            <CardContent
                sx={{
                    display: "grid",
                    gridAutoRows: "1fr auto",
                    height: "100%",
                }}
            >
                <Box>{children}</Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        mb: -2,
                    }}
                >
                    {link != "" && (
                        <HashLink to={link} style={{ textDecoration: "none" }}>
                            <Button variant="text" color="secondary">
                                Learn more
                            </Button>
                        </HashLink>
                    )}
                </Box>
            </CardContent>
        </Card>
    );
};
