import { Button } from "@mui/material";
import React from "react";

export const SocialLink = ({ children, to = "#", color = "white" }) => {
    return (
        <a
            href={to}
            style={{
                textDecoration: "none",
            }}
        >
            <Button
                color={color}
                variant="text"
                sx={{
                    justifyContent: "start",
                    ml: -1,
                    textTransform: "none",
                }}
            >
                {children}
            </Button>
        </a>
    );
};
