import React from "react";

import { Typography, Box, Button } from "@mui/material";
import { PageSection } from "../../containers";
import { SectionContent } from "../../containers/SectionContent/SectionContent";
import { HashLinkButton, LinkButton } from "../../components";

export const MissionSection = () => {
    return (
        <PageSection backgroundColor="secondary.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "3fr 4fr",
                            xs: "1fr",
                        },
                        py: 17,
                        color: "primary.contrastText",
                    }}
                >
                    <Box>
                        <Typography variant="h1">Our Mission</Typography>
                    </Box>
                    <Box>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            To expand the reach of computer science through
                            outreach to our local community.
                        </Typography>
                        <HashLinkButton color="info" to="about">
                            Learn More
                        </HashLinkButton>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
