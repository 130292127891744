import React from "react";

import { Typography, Box } from "@mui/material";
import { InitiativeCards, PageSection } from "../../containers";
import { SectionContent } from "../../containers/SectionContent/SectionContent";
import { HashLinkButton } from "../../components";

export const InitiativesSection = () => {
    return (
        <>
            <PageSection backgroundColor="background">
                <SectionContent>
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: {
                                md: "3fr 2fr",
                                xs: "1fr",
                            },
                            pt: 5,
                            pb: 15,
                        }}
                    >
                        <Box>
                            <Typography variant="h1">
                                <a id="initiatives">Our Initiatives</a>
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 5, mb: 0 }}>
                                A list of our current initiatives. Click to
                                learn more, or contact us if you have any
                                questions or ideas for new initiatives.
                            </Typography>
                        </Box>
                    </Box>
                </SectionContent>
            </PageSection>
            <PageSection backgroundColor="info.main">
                <SectionContent>
                    <Box
                        sx={{
                            mt: -10,
                            display: "inline-block",
                        }}
                    >
                        <InitiativeCards />
                    </Box>
                </SectionContent>
            </PageSection>
        </>
    );
};
