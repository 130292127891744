import React from "react";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Menu,
    Button,
    Icon,
    MenuItem,
    Link,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../../assets/INIT_Icon_Dark Green.svg";
import logoType from "../../assets/INIT_Logo Type_White.svg";
import { HashLink } from "react-router-hash-link";

const LinkMenuItem = ({ to, children, onClick = () => {} }) => {
    return (
        <HashLink
            to={to}
            style={{
                textDecoration: "none",
            }}
        >
            <MenuItem
                onClick={onClick}
                sx={{
                    color: "secondary.main",
                    minWidth: "200px",
                    fontSize: "1.1em",
                }}
            >
                {children}
            </MenuItem>
        </HashLink>
    );
};

// To display in toolbar if enough width
const HeaderLink = ({ to, children }) => {
    return (
        <HashLink to={to} color="secondary" style={{ textDecoration: "none" }}>
            <Button
                sx={{
                    my: 2,
                    color: "secondary.contrastText",
                }}
            >
                {children}
            </Button>
        </HashLink>
    );
};
export const Header = () => {
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar position="sticky" color="secondary">
            <Toolbar
                sx={{
                    animation: "fadeInDelayed  1000ms ease-out",
                }}
            >
                {/* Left-aligned logos */}
                <Link href="/">
                    <Box
                        sx={{
                            animation: "fadeInDelayed 500ms ease-out",
                        }}
                    >
                        <Icon sx={{ height: 1, width: "fit-content", pt: 2 }}>
                            <img src={logo} width={100} />
                        </Icon>
                        <Icon
                            sx={{
                                height: 1,
                                width: "fit-content",
                                pt: 2,
                                ml: 2,
                            }}
                        >
                            <img src={logoType} width={100} />
                        </Icon>
                    </Box>
                </Link>

                {/* Right-aligned hamburger icon which appears on mobile to bring up link menu */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: "flex", md: "none" },
                        justifyContent: "end",
                    }}
                >
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                        color="inherit"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: "block", md: "none" },
                        }}
                    >
                        <LinkMenuItem
                            to=""
                            onClick={() => handleCloseNavMenu()}
                        >
                            Home
                        </LinkMenuItem>
                        <LinkMenuItem
                            to="/#initiatives"
                            onClick={() => handleCloseNavMenu()}
                        >
                            Initiatives
                        </LinkMenuItem>
                        <LinkMenuItem
                            to="/about"
                            onClick={() => handleCloseNavMenu()}
                        >
                            About
                        </LinkMenuItem>
                        <LinkMenuItem
                            to="/contact"
                            onClick={() => handleCloseNavMenu()}
                        >
                            Contact
                        </LinkMenuItem>
                    </Menu>
                </Box>

                {/* Right-aligned links */}
                <Box
                    sx={{
                        flexGrow: 1,
                        display: { xs: "none", md: "flex" },
                        justifyContent: "end",
                    }}
                >
                    <HeaderLink to="/">Home</HeaderLink>

                    <HeaderLink to="/#initiatives">Initiatives</HeaderLink>
                    <HeaderLink to="/about">About</HeaderLink>
                    <HeaderLink to="/contact">Contact</HeaderLink>

                    {/* <HashLink to="/contact" style={{ textDecoration: "none" }}>
                        <Button
                            variant="contained"
                            color="success"
                            sx={{ my: 2, ml: 1, fontWeight: "bold" }}
                        >
                            Get Involved
                        </Button>
                    </HashLink> */}
                </Box>
            </Toolbar>
        </AppBar>
    );
};
