import React from "react";

import { Typography, Box, Button } from "@mui/material";
import { PageSection, SectionContent } from "../../containers";
import { LinkButton } from "../../components";
import profDevImg from "../../images/professional-development.jpg";

export const MembersSection = () => {
    return (
        <PageSection backgroundColor="primary.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "4fr 3fr",
                            xs: "1fr",
                        },
                        columnGap: "5%",
                        pt: 3,
                        pb: 10,
                    }}
                >
                    <Box>
                        <img
                            src={profDevImg}
                            style={{
                                width: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                    <Box sx={{ color: "primary.contrastText" }}>
                        <Typography
                            variant="h2"
                            sx={{
                                mt: { md: -10, xs: 1 },
                                mb: { md: 5, xs: 3 },
                                color: {
                                    md: "text.primary",
                                    xs: "primary.contrastText",
                                },
                            }}
                        >
                            Professional Development
                        </Typography>

                        <Typography variant="h4" mb={1}>
                            Building the skills of our members
                        </Typography>
                        <Typography variant="body1">
                            We aim to teach our members valuable skills which
                            prepare them for future professional opportunities.
                            We place an emphasis on practicing communication,
                            team work, and leadership.
                        </Typography>

                        <Typography variant="h4" sx={{ mt: 3 }} mb={1}>
                            Experience relevant to industry
                        </Typography>
                        <Typography variant="body1" mb={3}>
                            Our initiatives provide members with opportunities
                            to grow their skills related to computer science. A
                            number of our initiatives such as the DevTeam allow
                            members to apply computer science in ways that are
                            directly applicable to internships and jobs.
                        </Typography>

                        <LinkButton color="warning" to="/contact">
                            Get Involved
                        </LinkButton>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
