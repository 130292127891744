import React from "react";

import { Typography, Box } from "@mui/material";
import { PageSection } from "../../containers";
import { SectionContent } from "../../containers/SectionContent/SectionContent";
import { HashLinkButton } from "../../components";

export const AboutSection = () => {
    return (
        <PageSection backgroundColor="primary.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "4fr 3fr",
                            xs: "1fr",
                        },
                        columnGap: "5%",
                        pt: 3,
                        pb: 10,
                    }}
                >
                    <Box>
                        <div
                            style={{
                                paddingTop: "56.25%",
                                overflow: "hidden",
                                position: "relative",
                                WebkitBoxFlex: "1",
                                flexGrow: 1,
                            }}
                        >
                            <iframe
                                style={{
                                    bottom: 0,
                                    left: 0,
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    border: 0,
                                    height: "100%",
                                    width: "100%",
                                }}
                                src="https://mediahub.unl.edu/media/12019?format=iframe"
                                title="Video Player:  Initialize UNL "
                                allowFullScreen
                            />
                        </div>
                    </Box>
                    <Box sx={{ color: "primary.contrastText" }}>
                        <Typography
                            variant="h2"
                            sx={{
                                mt: { md: -10, xs: 1 },
                                mb: { md: 5, xs: 3 },
                                color: {
                                    md: "text.primary",
                                    xs: "primary.contrastText",
                                },
                            }}
                        >
                            What is Initialize?
                        </Typography>

                        <Typography variant="body1">
                            Initialize is an outreach group dedicated to
                            expanding the reach of computer science. We do so
                            through acts of community service, leadership,
                            mentorship, and interdisciplinary inclusion.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 1 }}>
                            We believe that computer science is now at the core
                            of every major discovery in the world, and we hope
                            to teach those around us that where those changes
                            come from should not be limited to only certain
                            interests, to only certain education paths, or to
                            one type of person
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 3 }} mb={2}>
                            Who we work with
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            We work with local businesses, non-profits, public
                            schools, colleges, and universities as a resource
                            and advocate for the importance of incorporating
                            computer science into every discipline in today's
                            modern world.
                        </Typography>
                        <HashLinkButton to="contact" color="warning">
                            Get Involved
                        </HashLinkButton>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
