import React from "react";

import {
    Typography,
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
} from "@mui/material";
import { PageSection, SectionContent } from "../../containers";

import EmailIcon from "@mui/icons-material/Email";
import { HashLink } from "react-router-hash-link";

export const QuestionsSection = () => {
    return (
        <PageSection backgroundColor="warning.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "2fr 1fr 2fr",
                            xs: "1fr",
                        },
                        py: 10,
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                            gridColumn: { md: "1 / 3", xs: "1" },
                            gridRow: { md: 1, xs: 1 },
                            width: "100%",
                            zIndex: 10,
                        }}
                    >
                        <Typography variant="h1" sx={{ mb: 3 }}>
                            <a id="questions">Still have questions?</a>
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            Checkout our{" "}
                            <HashLink to="/about#faq">FAQ</HashLink> to see if
                            there is anything we can answer.
                        </Typography>

                        <Typography variant="body1" sx={{ mb: 3 }}>
                            If you have any questions about our initiatives or
                            organization in general, the best way to reach us is
                            by email.
                        </Typography>

                        <a
                            href="mailto:initialize@unl.edu"
                            style={{
                                textDecoration: "none",
                            }}
                        >
                            <Button
                                variant="text"
                                sx={{
                                    justifyContent: "start",
                                    ml: -1,
                                    textTransform: "none",
                                }}
                            >
                                <EmailIcon sx={{ mr: 1 }} />
                                initialize@unl.edu
                            </Button>
                        </a>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
