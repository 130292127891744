import React from "react";
import SchoolIcon from "@mui/icons-material/School";
import { SocialLink } from "./SocialLink";

export const NvolveULink = () => {
    return (
        <SocialLink to="https://unl.campuslabs.com/engage/organization/initialize">
            <SchoolIcon sx={{ mr: 1 }} />
            NVolveU
        </SocialLink>
    );
};
