import React from "react";

import { Typography, Box, Button, Link } from "@mui/material";
import { PageSection, SectionContent } from "../../containers";

import logo from "../../assets/INIT_Icon_White.svg";
import { EmailLink, LinkButton } from "../../components";

const QuestionSection = ({ children }) => {
    return (
        <Box
            sx={{
                mt: 3,
                "& > *": {
                    mb: 1,
                },
            }}
        >
            {children}
        </Box>
    );
};

export const FaqSection = () => {
    return (
        <PageSection backgroundColor="secondary.light">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "2fr 1fr 2fr",
                            xs: "1fr",
                        },
                        py: 5,
                        color: "secondary.contrastText",
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                            gridColumn: { md: "1 / 3", xs: "1" },
                            gridRow: { md: 1, xs: 1 },
                        }}
                    >
                        <Typography variant="h1">
                            <a id="faq">FAQ</a>
                        </Typography>

                        <QuestionSection>
                            <Typography variant="h4">
                                I want to join Initialize. What should I do?
                            </Typography>
                            <Typography variant="body1" mb={3}>
                                Great! The first step is to contact us and let
                                us know you are interested. We will invite you
                                to our communication channels and let you know
                                where the next meeting is.
                            </Typography>
                            <LinkButton color="white" to="/contact">
                                Contact us
                            </LinkButton>
                        </QuestionSection>

                        <QuestionSection>
                            <Typography variant="h4">
                                When and where does Initialize meet?
                            </Typography>
                            <Typography variant="body1">
                                We typically meet once every two weeks somewhere
                                on campus. Contact us for exact details!
                            </Typography>
                        </QuestionSection>

                        <QuestionSection>
                            <Typography variant="h4">
                                How can I contact Initialize about a new
                                initiative opportunity?
                            </Typography>
                            <Typography variant="body1">
                                Have an after-school club, event, etc. which you
                                think we would be a great fit for? Our email is
                                the best way to contact us for initiative
                                inqueries.
                            </Typography>
                            <EmailLink />
                        </QuestionSection>
                    </Box>

                    <Box
                        sx={{
                            gridColumn: { md: "2 / 4", xs: "1" },
                            gridRow: { md: 1, xs: 2 },
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            mt: 3,
                            ml: { lg: 20 },
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                width: "100%",
                                objectFit: "cover",
                                opacity: 0.2,
                            }}
                        />
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
