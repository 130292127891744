import { ThemeProvider, CssBaseline } from "@mui/material";
import { ScrollToTop } from "./components";
import { About, Contact, Home, LegoRobotics } from "./pages";
import { theme } from "./shared/theme";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Layout } from "./containers";

function App() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <ScrollToTop>
                    <Routes>
                        <Route path="/" element={<Layout />}>
                            <Route path="" element={<Home />} />
                            <Route path="about" element={<About />} />
                            <Route path="contact" element={<Contact />} />
                            <Route
                                path="lego-robotics"
                                element={<LegoRobotics />}
                            />
                            <Route path="*" element={<Home />} />
                        </Route>
                    </Routes>
                </ScrollToTop>
            </Router>
        </ThemeProvider>
    );
}

export default App;
