import React from "react";

import { Typography, Box } from "@mui/material";
import { PageSection, SectionContent } from "../../containers";

export const HeroSection = () => {
    return (
        <PageSection backgroundColor="background.paper">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "3fr 2fr",
                            xs: "1fr",
                        },
                        py: 5,
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                        }}
                    >
                        <Typography variant="overline">
                            UNL Initialize's
                        </Typography>
                        <Typography variant="h1">
                            <a id="lego">Lego Robotics Education</a>
                        </Typography>
                        <Typography variant="h3">
                            Teaching computational thinking through hands on
                            activities.
                        </Typography>
                        <Typography variant="body1" sx={{ my: 5 }}>
                            Mentoring local students and youth groups in
                            computational thinking, programming, and problem
                            solving through various hands-on activities.
                        </Typography>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
