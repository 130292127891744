import React from "react";
import { QuestionsSection } from "./QuestionsSection";
import { SocialsSection } from "./SocialsSection";

export const Contact = () => {
    return (
        <>
            <SocialsSection />
            <QuestionsSection />
        </>
    );
};
