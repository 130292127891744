import React from "react";
import { PageSection, SectionContent } from "../../containers";
import { Typography, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { LinkButton } from "../../components";
import spikeKit from "../../images/spike-kit.jpg";
import scratchExample from "../../images/scratch-example.jpg";

export const EducationSection = () => {
    return (
        <PageSection backgroundColor="background.paper">
            <SectionContent>
                <Box
                    sx={{
                        pt: 10,
                        pb: 5,
                    }}
                >
                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: {
                                md: "3fr 2fr",
                                xs: "1fr",
                            },
                        }}
                    >
                        <Box>
                            <Typography variant="h1">
                                <a id="education">Education</a>
                            </Typography>
                            <Typography variant="body1" sx={{ mt: 3, mb: 5 }}>
                                We are passionate about educating the next
                                generation on the principles and practices of
                                computer science, no matter what their passion
                                is.
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: {
                                md: "4fr 3fr ",
                                xs: "1fr",
                            },
                            columnGap: "5%",
                        }}
                    >
                        <Box>
                            <Typography variant="h3" mb={2}>
                                Robotics Programming
                            </Typography>
                            <Typography variant="body1">
                                We teach kids how to program Lego robots. We
                                walk them through the entire process, from
                                building the robot to actually coding unique and
                                engaging programs.
                            </Typography>
                            <Typography variant="body1" mt={3}>
                                The curriculum is approximately a semester long
                                and covers topics such as
                            </Typography>
                            <ul>
                                <li>building a robot</li>
                                <li>controling the robot programmatically</li>
                                <li>if-else statements</li>
                                <li>loops</li>
                                <li>input sensors</li>
                            </ul>
                        </Box>

                        <Box>
                            <img
                                src={spikeKit}
                                style={{
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </SectionContent>

            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "1fr 2fr ",
                            xs: "1fr",
                        },
                        columnGap: "10%",
                        pt: 3,
                        pb: 10,
                    }}
                >
                    <img
                        src={scratchExample}
                        style={{
                            width: "100%",
                            objectFit: "cover",
                        }}
                    />
                    <Box>
                        <Typography variant="h2" mb={5}>
                            Coding & Scratch
                        </Typography>
                        <Typography variant="body1">
                            We teach kids to code with{" "}
                            <a href="https://scratch.mit.edu/">Scratch</a>, a
                            visual coding language for kids. Scratch is easy to
                            learn and understand, but teaches you the
                            fundamentals which are applicable to other
                            programming languages.
                        </Typography>

                        <Typography variant="h3" sx={{ mt: 3 }}>
                            Creative Coding
                        </Typography>
                        <Typography variant="body1" my={2}>
                            We have another initiative called Creative Coding
                            which also teaches kids how to program, but with an
                            emphasis on art and storytelling. Contact us for
                            more information.
                        </Typography>
                        <LinkButton to="/contact" color="primary">
                            Learn more
                        </LinkButton>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
