import React from "react";

import { Typography, Box, List } from "@mui/material";
import { InitiativeCard } from "../../components";

export const InitiativesCards = () => {
    return (
        <Box
            sx={{
                display: "grid",
                gridTemplateColumns: {
                    md: "repeat(4, 1fr)",
                    sm: "repeat(2, 1fr)",
                    xs: "1fr",
                },
                columnGap: "1%",
                rowGap: "1%",
                pt: 3,
                pb: 10,
            }}
        >
            <InitiativeCard
                title="Lego Robotics"
                subtitle="K-12 Computational Education"
                link="lego-robotics"
            >
                <Typography variant="body1">
                    Teaching computational thinking skills to K-12 students and
                    organizations. We mentor local students and youth groups
                    through various hands-on activities.
                </Typography>
                <Typography variant="body1" sx={{ mt: 1, mb: -2 }}>
                    Students learn
                </Typography>
                <ul style={{ marginBottom: 0 }}>
                    <li>problem solving</li>
                    <li>programming</li>
                    <li>communication</li>
                </ul>
            </InitiativeCard>
            <InitiativeCard
                title="Creative Coding"
                subtitle="K-12 Computational Education"
            >
                <Typography variant="body1">
                    Engaging students with diverse backgrounds and interests
                    through creative coding which emphasizes art and
                    storytelling.
                </Typography>
                <Typography variant="body1" sx={{ mt: 1, mb: -2 }}>
                    Students learn
                </Typography>
                <ul>
                    <li>programming</li>
                    <li>art &amp; storytelling</li>
                    <li>applications of computer science</li>
                </ul>
            </InitiativeCard>
            <InitiativeCard
                title="DevTeam"
                subtitle="Professional development for members"
            >
                <Typography variant="body1">
                    Members of Initialize collaborate with organizations to
                    develop new apps or work on existing products. Members gain
                    hands-on experience working with a team and product manager
                    throughout the entire development lifecycle.
                </Typography>
            </InitiativeCard>
            <InitiativeCard
                title="Camps & Events"
                subtitle="K-12 Computational Education"
            >
                <Typography variant="body1" sx={{ mt: 1, mb: -2 }}>
                    We host various computer science-centric events for
                    different organizations, such as
                </Typography>
                <ul>
                    <li>Girl & Boy Scouts</li>
                    <li>Native American STEM group</li>
                    <li>Belmont Community Center</li>
                </ul>
            </InitiativeCard>
        </Box>
    );
};
