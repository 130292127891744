import React from "react";
import { PageSection, SectionContent } from "../../containers";
import { Typography, Box, Button } from "@mui/material";
import { HashLinkButton } from "../../components";
import roboticsGroupImg from "../../images/robotics-group-hq.jpg";

export const VisionSection = () => {
    return (
        <PageSection backgroundColor="background.paper">
            <SectionContent>
                <Box
                    sx={{
                        pt: 10,
                        pb: 20,
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                        }}
                    >
                        <Typography variant="h1">
                            <a id="vision">Our Vision</a>
                        </Typography>
                        <Typography variant="h2">
                            Expanding the reach of computer science
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: "grid",
                            gridTemplateColumns: {
                                md: "4fr 3fr",
                                xs: "1fr",
                            },
                            columnGap: "1%",
                            mt: 5,
                        }}
                    >
                        <Box>
                            <Typography variant="h4" mb={1}>
                                Community Service
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>
                                We are dedicated to providing for our community
                                through service acts which have a positive
                                impact on the community.
                            </Typography>

                            <Typography variant="h4" mb={1}>
                                Leadership & Mentorship
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>
                                Our members are given mentorship training to
                                ensure that they can best expand computer
                                science through education outreach. Members have
                                many opportunities to practice these valuable
                                skills and share their valuable knowledge and
                                experience.
                            </Typography>

                            <Typography variant="h4" mb={1}>
                                Interdisciplinary Inclusion
                            </Typography>
                            <Typography variant="body1" sx={{ mb: 3 }}>
                                We believe that everyone belongs in computer
                                science, and we actively include groups which
                                are traditionally not included in the field.
                            </Typography>

                            <HashLinkButton color="white" to="/#initiatives">
                                Our Initiatives
                            </HashLinkButton>
                        </Box>

                        <Box>
                            <img
                                src={roboticsGroupImg}
                                style={{
                                    width: "100%",
                                    objectFit: "cover",
                                }}
                            />
                        </Box>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
