import React from "react";

import { Typography, Box, Button } from "@mui/material";
import { PageSection, SectionContent } from "../../containers";

import logo from "../../assets/INIT_Icon_White.svg";

export const InclusionSection = () => {
    return (
        <PageSection backgroundColor="primary.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "2fr 1fr 2fr",
                            xs: "1fr",
                        },
                        py: 5,
                        color: "secondary.contrastText",
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                            gridColumn: { md: "1 / 3", xs: "1" },
                            gridRow: { md: 1, xs: 1 },
                        }}
                    >
                        <Typography variant="h1">
                            <a id="inclusion">Inclusion</a>
                        </Typography>

                        <Typography variant="h3">
                            Interdisciplinary Involvment
                        </Typography>
                        <Typography variant="body1" sx={{ my: 3 }}>
                            Nearly every field in the modern world uses computer
                            science in some way, and our curriculum interweaves
                            many disciplines.
                        </Typography>

                        <Typography variant="h3">
                            Including everybody in computer science
                        </Typography>

                        <Typography variant="body1" sx={{ my: 3 }}>
                            It has been found that nearly all students are
                            interested in STEM fields at a young age, but that
                            some groups see a steep decline in interest around
                            middle school. We aim to get students involved in
                            STEM early on to curb this decline. We believe that
                            everyone, regardless of group or background, should
                            be in STEM.
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            gridColumn: { md: "2 / 4", xs: "1" },
                            gridRow: { md: 1, xs: 2 },
                            display: "flex",
                            alignItems: "end",
                            justifyContent: "end",
                            mt: 3,
                            ml: { lg: 20 },
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                width: "100%",
                                objectFit: "cover",
                                opacity: 0.2,
                            }}
                        />
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
