import React from "react";
import { FaqSection } from "./FaqSection";
import { MembersSection } from "./MembersSection";
import { VisionSection } from "./VisionSection";

export const About = () => {
    return (
        <>
            <VisionSection />
            <MembersSection />
            <FaqSection />
        </>
    );
};
