import React from "react";

import { Typography, Box, List, ListItem } from "@mui/material";
import { PageSection } from "../../containers";
import { SectionContent } from "../../containers/SectionContent/SectionContent";
import { LinkButton } from "../../components";
import legoRobotStudent from "../../images/lego-robot-student.jpg";

export const AboutSection = () => {
    return (
        <PageSection backgroundColor="warning.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "4fr 3fr",
                            xs: "1fr",
                        },
                        columnGap: "5%",
                        pt: 3,
                        pb: 10,
                        color: "text.primary",
                    }}
                >
                    <Box>
                        <img
                            src={legoRobotStudent}
                            style={{
                                width: "100%",
                                objectFit: "cover",
                            }}
                        />
                    </Box>
                    <Box>
                        <Typography
                            variant="h2"
                            sx={{
                                mt: { md: -10, xs: 1 },
                                mb: { md: 5, xs: 3 },
                            }}
                        >
                            What we do
                        </Typography>

                        <Typography variant="h3" mb={2}>
                            Teaching universal skills
                        </Typography>
                        <Typography variant="body1">
                            We believe that computational thinking skills apply
                            to every field. We teach applicable skills and
                            principles such as
                        </Typography>
                        <ul>
                            <li>problem solving</li>
                            <li>communiciation</li>
                            <li>organization and processing</li>
                        </ul>

                        <Typography variant="h3" mt={3} mb={2}>
                            Inspiring youth
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            We encourage students to explore how computational
                            thinking applies to their own life, and inspire them
                            to continue learning outside the classroom.
                        </Typography>
                        <LinkButton color="secondary" to="/contact">
                            Get Involved
                        </LinkButton>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
