import React from "react";

import { Typography, Box } from "@mui/material";
import { PageSection, SectionContent } from "../../containers";

export const HeroSection = () => {
    return (
        <PageSection backgroundColor="background.paper">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "3fr 2fr",
                            xs: "1fr",
                        },
                        py: 5,
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                        }}
                    >
                        <Typography variant="overline">
                            UNL's STEM Outreach Club
                        </Typography>
                        <Typography variant="h1">Initialize</Typography>
                        <Typography variant="h3">
                            Outreach. Mentorship. Inclusion.
                        </Typography>
                        <Typography variant="body1" sx={{ mt: 5 }}>
                            Expanding the reach of computer science.
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 5 }}>
                            To [INIT] is to add the value. To be part of it is
                            to add the meaning.
                        </Typography>
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
