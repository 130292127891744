import { Button, ListItem } from "@mui/material";
import React from "react";

import { HashLink as Link } from "react-router-hash-link";

export const FooterLinkListItem = ({ children }) => {
    return (
        <ListItem disableGutters sx={{ pt: 0, pb: 1 }}>
            {children}
        </ListItem>
    );
};
export const FooterLink = ({ children, to = "#" }) => {
    return (
        <Link to={to} underline="never" style={{ textDecoration: "none" }}>
            <Button
                color="white"
                variant="text"
                sx={{
                    justifyContent: "start",
                    ml: -1,
                }}
            >
                {children}
            </Button>
        </Link>
    );
};
