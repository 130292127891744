import React from "react";
import { SocialLink } from "./SocialLink";
import EmailIcon from "@mui/icons-material/Email";

export const EmailLink = () => {
    return (
        <SocialLink to="mailto:initialize@unl.edu">
            <EmailIcon sx={{ mr: 1 }} />
            initialize@unl.edu
        </SocialLink>
    );
};
