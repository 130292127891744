import React from "react";

import {
    Typography,
    Box,
    Button,
    List,
    ListItem,
    ListItemIcon,
} from "@mui/material";
import { PageSection, SectionContent } from "../../containers";
import logo from "../../assets/INIT_Icon_White.svg";
import { FacebookLink } from "../../components/SocialLink/FacebookLink";
import { NvolveULink } from "../../components/SocialLink/NvolveULink";
import { EmailLink, InstagramLink } from "../../components";

const SocialLinkListItem = ({ children }) => {
    return <ListItem>{children}</ListItem>;
};

const SocialIcon = ({ children }) => {
    return (
        <ListItemIcon sx={{ mr: -2, color: "secondary.contrastText" }}>
            {children}
        </ListItemIcon>
    );
};

export const SocialsSection = () => {
    return (
        <PageSection backgroundColor="primary.main">
            <SectionContent>
                <Box
                    sx={{
                        display: "grid",
                        gridTemplateColumns: {
                            md: "2fr 1fr 2fr",
                            xs: "1fr",
                        },
                        py: 10,
                        color: "secondary.contrastText",
                    }}
                >
                    <Box
                        sx={{
                            animation: "fadeInFromNone  1000ms ease-out",
                            gridColumn: { md: "1 / 3", xs: "1" },
                            gridRow: { md: 1, xs: 1 },
                            width: "100%",
                            zIndex: 10,
                        }}
                    >
                        <Typography variant="h1" sx={{ mb: 3 }}>
                            <a id="get-involved">Ready to make an impact?</a>
                        </Typography>

                        <Typography variant="h2" sx={{ mb: 3 }}>
                            Join one of our many initiatives and be part of
                            something greater
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            By joining, you are not only making an impact on the
                            community, but also growing your own skills and
                            gaining valuable experience.
                        </Typography>

                        <Typography variant="h3">
                            Join one of our socials and let us know you are
                            interested!
                        </Typography>
                        <List
                            disablePadding
                            sx={{
                                mt: 2,
                                display: "flex",
                                flexDirection: { md: "row", xs: "column" },
                                justifyItems: "center",
                                ml: { xs: -2 },
                            }}
                        >
                            <SocialLinkListItem>
                                <EmailLink />
                            </SocialLinkListItem>
                            <SocialLinkListItem>
                                <InstagramLink />
                            </SocialLinkListItem>
                            {/* <SocialLinkListItem>
                                <FacebookLink />
                            </SocialLinkListItem> */}
                            <SocialLinkListItem>
                                <NvolveULink />
                            </SocialLinkListItem>
                        </List>
                    </Box>

                    <Box
                        sx={{
                            gridColumn: { md: "2 / 4", xs: "1" },
                            gridRow: { md: 1, xs: 2 },
                            display: { md: "flex", xs: "none" },
                            alignItems: "end",
                            justifyContent: "end",
                            mt: 3,
                            ml: { lg: 20 },
                        }}
                    >
                        <img
                            src={logo}
                            style={{
                                width: "100%",
                                objectFit: "cover",
                                opacity: 0.2,
                            }}
                        />
                    </Box>
                </Box>
            </SectionContent>
        </PageSection>
    );
};
